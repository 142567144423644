.hero-warpper {
    /* position: relative; */
    color: white;
    padding-bottom: 2rem;
    z-index: 1;


}

.hero-container {
    justify-content: space-around;
    align-items: flex-end;
    padding: 1.5rem;

}

/* .hero-right {
    padding-right: 70px;
} */

.image-container {
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border: 8px solid rgb(255, 255, 255, 0.12);
    border-radius: 15rem 15rem 0 0;
}

.image-container>img {
    width: 100%;
    height: 100%;
}

.hero-left {
    gap: 2rem;
    /* padding-left: 70px; */

}

.hero-title {
    position: relative;
    z-index: 1;
}

.hero-title>h1 {
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}

.orange-circle {
    width: 4rem;
    height: 4rem;
    background: var(--orange-gradient);
    border-radius: 50%;
    position: absolute;
    right: 28%;
    top: -10%;
    z-index: -1;
}

.search-bar {
    background-color: white;
    border-radius: 5px;
    border: 3px solid rgba(120, 120, 120, 0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
}

.search-bar>input {
    border: none;
    outline: none;
}

.stats {
    width: 100%;
    justify-content: space-between;
}

.stat>:nth-child(1) {
    font-size: 2rem;
}

.stat>:nth-child(1)>:last-child {
    color: orange;
}

@media (max-width:640px) {
    .hero-container {
        margin-top: 2rem;
    }

    .hero-title>h1 {
        font-size: 2.5rem;
        line-height: 3rem;
    }

    .stats {
        justify-content: center;
        gap: 1.5rem;
        flex-wrap: wrap;
    }




    .stats>:nth-child(1) {
        font-size: 1.5rem;
    }

    .stats>:nth-child(2) {
        font-size: 0.8rem;
    }

    .image-container {
        width: 95%;
        height: 25rem;
    }

    .hero-right {
        width: 100%;
    }
}