.h-warpper {
    color: white;
}

.h-container {
    justify-content: space-between;
    color: var(--secondary);
    padding-top: 1rem;
    padding-bottom: 1rem;

}

.h-menu {
    gap: 2rem;
}

.h-menu>*:hover {
    cursor: pointer;
}

.menu-icon {
    display: none;
}

@media (max-width:768px) {
    .menu-icon {
        display: block;
    }

    .h-menu {
        color: var(--black);
        position: absolute;
        top: 3rem;
        right: 4rem;
        background-color: white;
        flex-direction: column;
        font-weight: 500;
        gap: 2rem;
        padding: 3rem;
        border-radius: 10px;
        align-items: start;
        transition: all 300ms ease-in;
        z-index: 99;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    }
}