.r-container {
    overflow: visible;
    position: relative;
}

.r-wrapper {
    overflow: visible;
    position: relative;
}

.r-head {
    margin-bottom: 2rem;
}

.r-card {
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: max-content;
    margin: auto;
    transition: all 300ms ease-in;
}

.r-card>img {
    width: 100%;
    max-width: 15rem;

}

.r-card .r-price {
    font-size: 1.2rem;
    font-weight: 600;
}

.r-card>:nth-child(3) {
    font-size: 1.5rem;
}

.r-card>:nth-child(4) {
    font-size: 0.7rem;
    width: 15rem;
}

.r-card:hover {
    cursor: pointer;
    scale: 1.025;
    background: linear-gradient(180deg,
            rgba(255, 255, 255, 0)0%,
            rgba(136, 160, 255, 0.46)217.91%);
    box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
}

.swiper-horizontal {
    overflow: visible;
}

.r-buttons {
    /* position: absolute; */
    gap: 1rem;
    top: -4rem;
    right: 0;
    z-index: 10;
}

.r-buttons button {
    font-size: 1.2rem;
    color: var(--blue);
    padding: 0.2rem 0.8rem;
    border: none;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}

@media (max-width:640px) {
    .r-head {
        align-items: center;
    }

}